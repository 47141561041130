import apiClient from './basicApi'

function approveArticle(articleId, approvalId) {
  return apiClient
    .put(`approve/article`, {
      articleId: articleId,
      approvalId: approvalId,
    })
    .then((res) => res.data)
}

function approveAnswer(answerId, approvalId) {
  return apiClient
    .put(`approve/answer`, {
      answerId: answerId,
      approvalId: approvalId,
    })
    .then((res) => res.data)
}

function approveComment(commentId, approvalId) {
  return apiClient
    .put(`approve/comment`, {
      commentId: commentId,
      approvalId: approvalId,
    })
    .then((res) => res.data)
}

export { approveArticle, approveAnswer, approveComment }
