import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'
import queryString from 'query-string'

import {
  approveArticle,
  approveAnswer,
  approveComment,
} from '@api/approvePosts'

import ToggleComponentContext from '@providers/toggleComponentProvider'

import Button from '@objects/button'
import Container from '@objects/container'
import Stage from '@components/stage'
import Headline from '@objects/headline'

function ApprovePostPage({ location }) {
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const intl = useIntl()
  const [postState, setPostState] = useState({
    status: 'default',
    type: 'dialog',
  })

  useEffect(() => {
    const query = queryString.parse(location.search)

    if (query.articleId) {
      approveArticle(parseInt(query.articleId), query.approvalId)
        .then((data) => {
          data.holidayModusEnabled === true
            ? toggleOverlay(true, 'holidayModeDialog', 'default', {
                trackingID: 'holidayModeDialog',
                width: 'sm',
                onAcceptance: () => toggleOverlay(false),
              })
            : ''
          if (data.status === 'EditorApprovalNeeded') {
            setPostState(() => {
              return {
                status: `${
                  data.holidayModusEnabled === true
                    ? 'holidayapproval'
                    : 'approval'
                }`,
                type: data.magazineId ? 'magazine' : 'dialog',
              }
            })
          } else {
            saveAccessToken(data.accessToken)
            navigate(
              `${intl.formatMessage({ id: 'forum.path' })}${data.articleId}`
            )
          }
        })
        .catch((e) =>
          setPostState(() => {
            return {
              status: 'error',
              type: 'dialog',
            }
          })
        )
    } else if (query.answerId) {
      approveAnswer(parseInt(query.answerId), query.approvalId)
        .then((data) => {
          data.holidayModusEnabled === true
            ? toggleOverlay(true, 'holidayModeDialog', 'default', {
                trackingID: 'holidayModeDialog',
                width: 'sm',
                onAcceptance: () => toggleOverlay(false),
              })
            : ''
          if (data.status === 'EditorApprovalNeeded') {
            setPostState({
              status: `${
                data.holidayModusEnabled === true
                  ? 'holidayapproval'
                  : 'approval'
              }`,
              type: data.magazineId ? 'magazine' : 'dialog',
            })
          } else {
            saveAccessToken(data.accessToken)
            if (data.magazineId) {
              navigate(
                `${intl.formatMessage({ id: 'magazine.path' })}${
                  data.magazineId
                }?answerid=${data.answerId}`
              )
            } else {
              navigate(
                `${intl.formatMessage({ id: 'forum.path' })}${
                  data.articleId
                }?answerid=${data.answerId}`
              )
            }
          }
        })
        .catch((e) =>
          setPostState({
            status: 'error',
            type: 'dialog',
          })
        )
    } else if (query.commentId) {
      approveComment(parseInt(query.commentId), query.approvalId)
        .then((data) => {
          data.holidayModusEnabled === true
            ? toggleOverlay(true, 'holidayModeDialog', 'default', {
                trackingID: 'holidayModeDialog',
                width: 'sm',
                onAcceptance: () => toggleOverlay(false),
              })
            : ''
          if (data.status === 'EditorApprovalNeeded') {
            setPostState({
              status: `${
                data.holidayModusEnabled === true
                  ? 'holidayapproval'
                  : 'approval'
              }`,
              type: data.magazineId ? 'magazine' : 'dialog',
            })
          } else {
            saveAccessToken(data.accessToken)
            if (data.magazineId) {
              navigate(
                `${intl.formatMessage({ id: 'magazine.path' })}${
                  data.magazineId
                }?answerid=${data.answerId}&commentid=${data.commentId}`
              )
            } else {
              navigate(
                `${intl.formatMessage({ id: 'forum.path' })}${
                  data.articleId
                }?answerid=${data.answerId}&commentid=${data.commentId}`
              )
            }
          }
        })
        .catch((e) =>
          setPostState({
            status: 'error',
            type: 'dialog',
          })
        )
    } else {
      navigate(intl.formatMessage({ id: 'dialog.path' }))
    }
  }, [])

  function saveAccessToken(token) {
    localStorage.setItem('accessToken', token)
  }

  function errorRender(state) {
    if (state.status !== 'default') {
      return (
        <>
          <Headline>
            <FormattedMessage
              id={`page.forward.confirm.copy.${state.status}`}
            />
          </Headline>
          {state.type === 'magazine' ? (
            <Button
              link={intl.formatMessage({ id: 'magazine.path' })}
              color={'red'}
            >
              <FormattedMessage id="button.magazine" />
            </Button>
          ) : (
            <Button
              link={intl.formatMessage({ id: 'dialog.path' })}
              color={'red'}
            >
              <FormattedMessage id="button.onlinedialog" />
            </Button>
          )}
        </>
      )
    } else {
      return (
        <Headline>
          <FormattedMessage id="page.forward.confirm.copy" />
        </Headline>
      )
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Stage
        alignTop={true}
        type={'generic'}
        title={intl.formatMessage({
          id: `page.forward.confirm.title${
            postState.status !== 'default' ? `.${postState.status}` : ''
          }`,
        })}
        ariaLabel="arialabel.confirmcontribution"
      />

      <Container
      ariaLabel="arialabel.confirmcontribution"
      >{errorRender(postState)}</Container>
    </>
  )
}

ApprovePostPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  modules: PropTypes.array,
}

export default ApprovePostPage
